import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import logo from './logo.png';
import logoWhite from './logo-white.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleUser, faChevronDown, faChevronUp, faBars
} from '@fortawesome/pro-solid-svg-icons';
import AccountMenu from './AccountMenu';
import AuctionMenu from './AuctionMenu';
import AuthContext from 'src/contexts/AuthContext';
import { headerGray, elRed } from 'src/styles/colors';
import ClickOutside from 'src/elements/ClickListener';
import { Dimensions } from 'react-native-web';
import WebContacts from './WebContacts';
import BlogContext from 'src/contexts/BlogContext';

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;
const mediumScreen = dimensions.width > 480 && dimensions.width < 1024;

const LogoContainer = styled.div`

`;

const logoStyle = {
  height: '70%',
  marginTop: '5px'
}

const mobileLogoStyle = {
  height: '75%'
}

const LinkContainer = styled.div`
  gap: 30px;
  display: flex;
  flex: 1;
  width: ${smallScreen ? '50%' : '25%'};
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-left: 20px;
  margin-top: 75px;
  font-weight: 500;
  font-size: 18px;
  background-color: #000;
  position: absolute;
  right: 0;

`;

const RegisterButtonContainer = styled.div`
  width: fit-content;
  justify-content: flex-end;
  margin-left: 1%;
`;

const RegisterButton = styled(Link)`
  background-color: red;
  border-radius: 8px;
  padding: 20px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: center;
`;

const MobileMenu = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showAuctionMenu, setShowAuctionMenu] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);
  const [showWebContacts, setShowWebContacts] = useState(false);
  const {
    clearBlogs
  } = useContext(BlogContext);

  const salePath = useLocation().pathname.includes("/bidding/");

  const MobileContainer = styled.div`
    display: flex;
    height: 78px;
    top: 0;
    padding-top: 10px;
    z-index: 999999;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #000;
    color: #fff;
    @media (max-width: 480px) {
      > img {
        width: 90%;
      }
    }
  `;

  const linkStyle = {
    textDecoration: 'none',
    cursor: 'pointer',
    color: '#fff',
    marginRight: '20px',
  }

  const logoLinkStyle = {
    maxWidth: '30%',
    textDecoration: 'none',
    cursor: 'pointer',
    color: '#fff',
    marginRight: '20px',
  }

  return (
    <MobileContainer>
      {/* <LogoContainer> */}
        <Link to="/" style={logoLinkStyle}>
          <img src={logoWhite} alt="Equiplinc" style={logoStyle} />
        </Link>
      {/* </LogoContainer> */}
      <FontAwesomeIcon
        icon={faBars}
        size='2x'
        style={{color: '#fff', marginTop: '20px', maxWidth: '10%',
          marginLeft: '-20px', marginRight: '0%', cursor: 'pointer',
          zIndex: 100
        }}
        onClick={() => setShowMobileMenu(!showMobileMenu)}
      />
      {showMobileMenu ?
        <LinkContainer>
          <Link to="/" style={linkStyle}>Home</Link>
          <Link to="/about" style={linkStyle}>About</Link>
          <Link to="/meet-our-team" style={linkStyle}>Meet Our Team</Link>
          <div
            onClick={() => setShowAuctionMenu(!showAuctionMenu)}
            style={{cursor: 'pointer'}}
          >
            Auction
            {showAuctionMenu ?
              <FontAwesomeIcon
                icon={faChevronUp}
                size='sm'
                style={{color: elRed, marginLeft: '15px', marginTop: '2px',
                  marginRight: '10px', cursor: 'pointer'
                }}

              /> :
              <FontAwesomeIcon
                icon={faChevronDown}
                size='sm'
                style={{color: elRed, marginLeft: '15px', marginTop: '-2px',
                  marginRight: '10px', cursor: 'pointer'
                }}
              />
            }
          </div>
          {showAuctionMenu &&
            <AuctionMenu
              showMenu={showAuctionMenu}
              setShowMenu={setShowAuctionMenu}
              isLoggedIn={isLoggedIn}
            />
          }
          <Link to="/inventory" style={linkStyle}>Inventory</Link>
          <Link to="/buyNow" style={linkStyle}>Buy Now</Link>
          {/* <Link to="/sales" style={linkStyle}>Sales</Link> */}
          <Link to="/locations" style={linkStyle}>Locations</Link>
          <Link to="/blog" style={linkStyle} onClick={() => clearBlogs()}>
            Blog
          </Link>
          {!isLoggedIn && <a href="/login" style={linkStyle}>Login</a>}
          <WebContacts
            showWebContacts={showWebContacts}
            setShowWebContacts={setShowWebContacts}
          />
          <RegisterButtonContainer>
            {isLoggedIn ?
              <>
                <FontAwesomeIcon
                  icon={faCircleUser}
                  size='2xl'
                  style={{color: elRed, cursor: 'pointer'}}
                  onClick={() => setShowAccountMenu(!showAccountMenu)}
                />
                <AccountMenu showMenu={showAccountMenu} setShowMenu={setShowAccountMenu} />
              </> :
              null
            }
          </RegisterButtonContainer>
        </LinkContainer> :
        null
      }
    </MobileContainer>
  )

}

export default MobileMenu;