import React, { useCallback, useContext, useState } from 'react';
import { View, Pressable } from 'react-native';
import { useQuery } from '@apollo/client';
import styled from 'styled-components/native';

import DataState from 'src/components/DataState';
import Modal from 'src/elements/NativeModal';
import { Text } from 'src/styles';
import * as colors from 'src/styles/colors';
import { dollars } from 'src/utils/stringHelpers';
import AuthContext from 'src/contexts/AuthContext';

import {
  SaleAssetContainer,
  BorderedContainer,
  Title,
  Going,
  GoingText,
  goingColor,
} from './elements';
import { BIDDING_SALE_ASSETS } from './queries';
import BidGroupChoose from './BidGroupChoose';
import AnnouncementIcon from '../Sales/Announcements/AnnouncementIcon';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const Container = styled(View)`
  height: 100%;
  flex: 1;
  overflow: auto;
  border: none;
`;

const Thumbnail = styled(View)`
  width: 180px;
  align-items: center;
  text-align: left;
`;

const CurrentThumbnail = styled(View)`
  height: 150px;
  width: 240px;
  align-items: center;
  text-align:left;
`;

const CurrentSaleAssetContainer = styled(SaleAssetContainer)`
  border-top-width: 2px;
  border-top-style: solid;
  border-top-color: ${colors.elRed};
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: ${colors.elRed};
  background-color: ${colors.elGreen};
`;

const LargeText = styled(Text)`
  font-size: 18px;
`;

const SmallText = styled(Text)`
`;

const WatchingText = styled(Text)`
  margin-left: 20;
  color: green;
  font-weight: 900;
`;

const StatusText = styled(Text)`
  font-weight: 600;
`;

const NextWatching = styled(View)`
  background-color: white;
  margin-vertical: 6px;
`;

const groupedWith = (saleAsset, saleAssets) => {
  if (!saleAsset.pendingBidGroupId) { return null; }

  return saleAssets.filter(entry => (
    entry.pendingBidGroupId === saleAsset.pendingBidGroupId
  ));
};

const getBorderStyle = (saleAsset, inGroup) => {
  if(inGroup?.length && saleAsset === inGroup[0]) {
    return {border: '2px solid blue', borderBottom: 'none'};
  }else if(inGroup?.length && saleAsset === inGroup[inGroup.length - 1]) {
    return {border: '2px solid blue', borderTop: 'none'};
  }else if(inGroup?.length && inGroup.includes(saleAsset)) {
    return {borderLeft: '2px solid blue', borderRight: '2px solid blue'};
  }else {
    return {border: 'none'};
  }
}

export const CurrentSaleAsset = ({ saleAsset, saleAssets}) => {
  const { asset } = saleAsset;

  const inGroup = groupedWith(saleAsset, saleAssets);

  return (
    <CurrentSaleAssetContainer style={getBorderStyle(saleAsset, inGroup)}>
      <Thumbnail>
        {asset.primaryPhotoUrl && (
          <img
            src={asset.primaryPhotoUrl}
            alt={asset.name}
            height="100"
            style={{ maxWidth: '100%', borderRadius: '10px' }}
          />
        )}
      </Thumbnail>
      <View style={{flex: 1}}>
        {goingColor(saleAsset) && (
          <Going style={{ backgroundColor: goingColor(saleAsset) }}>
            <GoingText>{saleAsset.biddingStatus}</GoingText>
          </Going>
        )}
        <View style={{display: 'flex', flexDirection: 'row'}}>
          <Title style={{color: '#fff'}}>{saleAsset.lotNumber}</Title>
          <AnnouncementIcon
            saleAsset={saleAsset}
          />
        </View>
        {inGroup && (
          <Title style={{backgroundColor: 'yellow'}}>
            Choice:{' '}
            {inGroup.map(entry => entry.lotNumber).join(' ')}
          </Title>
        )}
        <SmallText style={{color: '#fff'}}>{asset.name}</SmallText>
        <SmallText style={{color: '#fff'}}>{asset.vin}</SmallText>
        <SmallText style={{color: '#fff'}}>
          {asset.cityState?.toUpperCase()}
        </SmallText>
      </View>
    </CurrentSaleAssetContainer>
  );
};

export const SaleAsset = ({ saleAsset, active, even, saleAssets, saleId}) => {
  const { asset } = saleAsset;
  const { currentUser } = useContext(AuthContext);
  // const navigate = useNavigate();
  const sold = currentUser?.buyer &&
    saleAsset.activeOffer?.buyer?.id === currentUser?.buyer?.id;
  const pendingSellerConfirmation = saleAsset.biddingStatus == "Sold" && saleAsset.pendingSellerConfirmation;
  console.log("saleAsset", saleAsset)
  console.log("pending?", pendingSellerConfirmation);
  console.log("sold?", sold)
  const inGroup = groupedWith(saleAsset, saleAssets);

  let highlight;
  if (pendingSellerConfirmation) {
    highlight = 'red';
  } else if (sold) {
    highlight = 'green';
  }

  const slug = asset => (
    asset.name.replace(/[^0-9a-z]+/gi, '-')
  );

  return (
    // <Link
    //   to={`/inventory/${asset.id}/${slug(asset)}`}
    //   target={'_blank'}
    // >
    <Pressable
      onPress={() => window.open(`/sales/${saleId}/inventory/${asset.id}/${slug(asset)}`)}
    >
      <SaleAssetContainer
        active={active}
        highlight={highlight}
        groupPosition={saleAsset.groupPosition}
        even={even}
        sold={'Sold' === saleAsset.biddingStatus}
        style={getBorderStyle(saleAsset, inGroup)}
      >
        <Thumbnail>
          {asset.primaryPhotoUrl && (
            <img
              src={asset.primaryPhotoUrl}
              alt={asset.name}
              height="100"
              style={{ maxWidth: '100%', borderRadius: '10px' }}
            />
          )}
        </Thumbnail>
        <View style={{flex: 1}}>
          <View style={{display: 'flex', flexDirection: 'row'}}>
            <SmallText>{saleAsset.lotNumber}</SmallText>
            <AnnouncementIcon
              saleAsset={saleAsset}
            />
            {saleAsset.watching && (
              <WatchingText>★ WATCHING ★</WatchingText>
            )}
          </View>
          {goingColor(saleAsset) && (
            <GoingText style={{ color: goingColor(saleAsset) }}>
              {saleAsset.biddingStatus}
            </GoingText>
          )}
          {'Passed' === saleAsset.biddingStatus && (
            <StatusText>PASSED</StatusText>
          )}
          {'Sold' === saleAsset.biddingStatus && (
            <StatusText>
              {pendingSellerConfirmation ?
                'Pending Seller Confirmation' : 'SOLD'
              }
              {' - '}{dollars(saleAsset.biddingSoldAmount)}
              {/* {' - '}{saleAsset.biddingSoldBuyerDetails} */}
            </StatusText>
          )}
          <SmallText>{asset.name}</SmallText>
          <SmallText>{asset.vin}</SmallText>
          <SmallText>{asset.cityState?.toUpperCase()}</SmallText>
        </View>
      </SaleAssetContainer>
    </Pressable>
  );
};

export const isActive = (sale, saleAsset) => {
  return (

  saleAsset.id === sale.biddingSaleAsset?.id
)};

const Tabs = styled(View)`
  flex-direction: row;
  gap: 10px;
`;

const TabContainer = styled(Pressable)`
  flex: 1;
  padding: 6px;
  background-color: ${props => props.active ? colors.elRed : '#fff'};
  borderTopRightRadius: 10px;
  borderTopLeftRadius: 10px;
`;

const TabLabel = styled(Text)`
  color: ${props => props.active ? 'white' : 'black'};
  font-weight: 600;
  text-align: center;
`;

const Tab = ({ name, tab, setTab }) => {
  const isActive = tab === name;

  return (
    <TabContainer active={isActive} onPress={() => setTab(name)}>
      <TabLabel active={isActive}>{name}</TabLabel>
    </TabContainer>
  );
};

const SaleAssets = ({ sale, updateSale}) => {
  const { currentUser, isLoggedIn } = useContext(AuthContext);
  const [tab, setTab] = useState('Live');

  const { data } = useQuery(BIDDING_SALE_ASSETS, {
    variables: { saleId: sale.id }
  });

  const activeRef = useCallback(element => {
    if (element) {
      element.parentNode.scrollTop = element.offsetTop;
    }
  }, []);

  if (!data) { return <DataState.Loading />; }

  const currentIndex = data.saleAssets.findIndex(item => isActive(sale, item));
  let saleAssets = data.saleAssets;

  let nextWatching = false;

  saleAssets.forEach((saleAsset, index) => {
    if (saleAsset.watching && index > (currentIndex || 0)) {
      if (!nextWatching) {
        nextWatching = index - (currentIndex || 0);
      }
    }
  });

  if ('Live' === tab) {
    saleAssets = data.saleAssets.slice(
      Math.max(0, currentIndex - 2),
      currentIndex + 6
    );
  } else if ('My Purchases' === tab) {
    saleAssets = data.saleAssets.filter(item => (
      item.activeOffer?.buyer?.id === currentUser?.buyer.id
    ));
  }

  const groups = {};

  saleAssets.forEach((saleAsset, index) => {
    if (saleAsset.pendingBidGroupId) {
      if (groups[saleAsset.pendingBidGroupId]) {
        groups[saleAsset.pendingBidGroupId].push(saleAsset.id);
      } else {
        groups[saleAsset.pendingBidGroupId] = [saleAsset.id];
      }
    }
  });

  saleAssets = saleAssets.map(saleAsset => {
    const group = groups[saleAsset.pendingBidGroupId];
    saleAsset = {...saleAsset };

    if (group) {
      const indexInGroup =
        group.indexOf(saleAsset.id);

      if (0 === indexInGroup) {
        saleAsset.groupPosition = 'first';
      } else if (indexInGroup === group.length - 1) {
        saleAsset.groupPosition = 'last';
      } else {
        saleAsset.groupPosition = 'middle';
      }
    }

    return saleAsset;
  });

  return (
    <>
      <Tabs>
        <Tab name="Live" tab={tab} setTab={setTab} />
        <Tab name="Catalog" tab={tab} setTab={setTab} />
        {isLoggedIn && currentUser && (
          <Tab name="My Purchases" tab={tab} setTab={setTab} />
        )}
      </Tabs>
        {nextWatching && (
          <NextWatching>
            <WatchingText>Watched Asset {nextWatching} Away</WatchingText>
          </NextWatching>
        )}
      <Container>
        {saleAssets.map((saleAsset, index) => (
          <React.Fragment key={saleAsset.id}>
            {('Live' === tab || 'Catalog' === tab) && isActive(sale, saleAsset) ? (
              <>
                <div ref={'Live' === tab ? activeRef : null}>
                </div>
                <CurrentSaleAsset
                  saleAsset={saleAsset}
                  saleAssets={saleAssets}
                />
              </>
            ) : (
              <SaleAsset
                saleAsset={saleAsset}
                active={isActive(sale, saleAsset)}
                even={index % 2 === 0}
                saleAssets={saleAssets}
                saleId={sale.id}
              />
            )}
          </React.Fragment>
        ))}
        {sale.bidGroupChoiceId &&
          sale.bidGroupBuyerId === currentUser?.buyer?.id && (
            <Modal
              showModal
              modalType="Choice"
              setShowModal={false}
            >
              <BidGroupChoose
                sale={sale}
                saleAssets={saleAssets}
              />
            </Modal>
          )
        }
        <View style={{ height: '80vw', backgroundColor: 'white' }}></View>
      </Container>
    </>
  );
};

export default SaleAssets;
