import React, { useContext, useState, useEffect } from 'react';
import { Pressable, Text, View, Dimensions } from 'react-native';
import { useQuery, useMutation } from '@apollo/client';
import Filter from 'src/elements/Filter';
import BlogContext from '../../contexts/BlogContext';
import ReactPaginate from "react-paginate";
import styled from 'styled-components';
import {
  MainContainer,
  ContentContainer,
  PageTitle,
} from 'src/elements';
import Featured from './Featured';
import DataState from '../DataState';

import {
  BLOGS_WITH_COUNT
} from './queries';
import Card from './Card';

import {
  MainBlogContainer,
  CardContainer,
  SideBar
} from './elements';
import SearchInput from './SearchInput';


const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 1100;
console.log("Width", dimensions.width);

const inputStyle = {
  marginBottom: '10px'
}

const StyledReactPaginate = styled(ReactPaginate)`
  display: flex;
  flex-wrap: nowrap;
  font-size: smaller;
  color: black;
`;

const Blogs = ({  }) => {
  const [filterOptions, setFilterOptions] = useState();
  const [filters, setFilters] = useState({});
  const [settings, setSettings] = useState({});
  const [initialLoad, setIntialLoad] = useState(true);
  const {
    currentBlogTag, currentBlogs, setCurrentBlogs, currentItemOffset,
    setCurrentItemOffset, clearBlogs, currentFilterPage, setCurrentFilterPage,
    currentTerm, setFeaturedLabel, setAllBlogTags
  } = useContext(BlogContext);

  const [itemOffset, setItemOffset] = useState(
    currentItemOffset !== -1 ? currentItemOffset : 0
  );
  const [currentPage, setCurrentPage] = useState(
    currentFilterPage !== -1 ? currentFilterPage : 0
  );

  const queryVariables = {
    filterColumns: ['tag'],
    filterNames: [currentBlogTag],
    scopes: ['ordered'],
    search: currentTerm || ''
  };

  const { loading, data, refetch } = useQuery(BLOGS_WITH_COUNT,
    { variables: queryVariables }
  );

  const items = data?.blogsWithCount.entries || [];

  const endOffset = itemOffset + 24;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / 24);

  useEffect(() => {
    // clearBlogs();
    setIntialLoad(false);
    if(items?.length && !currentBlogs.length) {
      setFeaturedLabel(data?.blogsWithCount?.featuredLabel);
      setCurrentBlogs(items);
      setAllBlogTags(data?.blogsWithCount?.tags);
    }
  }, [items])

  const handlePageClick = (event) => {
    const newOffset = items?.length ? (event.selected * 24) % items.length : 24;
    setCurrentPage(items.length ? event.selected : 0);
    setItemOffset(newOffset);
    setCurrentItemOffset(newOffset);
  };

  if(loading) return <DataState.Loading />;

  return (
    <MainContainer>
      <ContentContainer>
        <PageTitle>Blog</PageTitle>
        <View
          style={{
            flexDirection: smallScreen ? 'column-reverse' : 'row',
            gap: '20px'
          }}
        >
          <View
            style={{
              // flexDirection: smallScreen ? 'column' : 'row',
              width: smallScreen ? '100%' : '75%',
              maxWidth: '100%',
              flexWrap: 'wrap'
            }}
          >
            <MainBlogContainer
              flexDirection={smallScreen ? 'column' : 'row'}
            >
                {items?.map(blog => {
                  return (
                    <CardContainer
                      width={smallScreen ? '100%' : '49%'}
                      maxWidth={smallScreen ? '100%' : '49%'}
                    >
                      <Card blog={blog} refetch={refetch} fromShow={false} />
                    </CardContainer>
                  )
                })}
            </MainBlogContainer>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <StyledReactPaginate
                breakLabel="..."
                nextLabel=""
                forcePage={currentPage}
                onPageChange={handlePageClick}
                pageRangeDisplayed={4}
                pageCount={pageCount}
                previousLabel=""
                renderOnZeroPageCount={null}
                className="pagination-links"
                containerClassName = {"page-links-container"}
                activeLinkClassName='active-page'
              />
            </div>
          </View>
          <SideBar
            width={smallScreen ? '100%' : '50%'}
            maxWidth={smallScreen ? '100%' : '25%'}
          >
            <SearchInput />
            <Featured refetch={refetch} />
          </SideBar>
        </View>
      </ContentContainer>
    </MainContainer>
  );
};

export default Blogs;
