import React from 'react';
import styled from 'styled-components';
import hero from './meetTeamHero.png';
import dotGrid from './dot-grid.png';
import {
  Container,
  ColumnContainer,
  TextContainer,
  HeaderLabel,
  RedSeparator,
  ImageContainer,
  RegularText
} from './elements';
import FinanceLink from '../FinanceLink';

const LeftColumn = styled.div`
  display: flex;
  flex: 1;
  background-image: url(${dotGrid});
  background-repeat: no-repeat;
  background-size: 25%;
`;

const AboutUsHeader = () => {
  return (
    <>

      <Container>
        <ColumnContainer>
          <LeftColumn>
            <TextContainer>
              <HeaderLabel>
                Meet Our Team
              </HeaderLabel>
              <RedSeparator />
            </TextContainer>
          </LeftColumn>
          <ImageContainer>
            <img src={hero} alt='Equiplinc Team'
              style={{
                width: '100%',
              }}
            />
          </ImageContainer>
        </ColumnContainer>
      </Container>
      
    </>
  )
}

export default AboutUsHeader;