import React from "react";
import TeamHeader from "../Headers/TeamHeader";
import styled from "styled-components";
import dotGrid from '../Headers/dot-grid.png';
import about from './about.png';
import Testimonials from "../Testimonials";
import CTA from "../CTA";
import { Dimensions } from "react-native-web";
import { Text } from 'src/styles';

import jason from './TeamPhotos/Jason.png';
import jimmie from './TeamPhotos/Jimmie.png';
import jim from './TeamPhotos/Jim.png';
import maria from './TeamPhotos/Maria.png';
import rebecca from './TeamPhotos/Rebecca.png';
import eric from './TeamPhotos/Eric.png';
import marty from './TeamPhotos/Marty.png';
import tyler from './TeamPhotos/Tyler.png';
import brittany from './TeamPhotos/Brittany.png';
import bre from './TeamPhotos/Bre.png';
import leah from './TeamPhotos/Leah.png';
import hannah from './TeamPhotos/Hannah.png';

const dimensions = Dimensions.get('window');
const smallScreen = dimensions.width < 800;

const Container = styled.div`

`;

const ContentContainer = styled.div`

  max-width: 100%;
  background-image: url(${dotGrid});
  background-size: 20%;
  background-repeat: no-repeat;

  @media(max-width: 768px) {
    padding-left: 2%;
    padding-right: 2%;
  }

  @media(min-width: 768px) {

    margin-top: 60px;
    margin-bottom: 60px;

  }
`;

const LeftColumn = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
  width:350px;

  @media(max-width: 768px) {
    display: none;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 20px;
  padding-right: 40px;

  @media(max-width: 768px) {
    width: 100%;
  }
`;

const StyledHeader = styled.h2`
  font-size: 22px;

  @media(min-width: 768px) {
    font-size: 50px;
  }
`;

const RegularText = styled.div`
  @media(max-width: 768px) {
    font-size: 12px;
    line-height: 20px;
  }
`;

const ULContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 25px;
  margin-top: -25px;
`;

const ULLeft = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: column;
`;

const ULRight = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #EB1C22;
  padding-bottom: 10px;
  margin-bottom: 10px;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const PictureBox = styled.div`
  width: 33%;
  text-align: center;
   @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const BioBox = styled.div`
  width: 67%;
  text-align: left;
   @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 10px;
  }
`;

const Team = () => {
  return (
    <Container>
      <TeamHeader />
      <ContentContainer>
        <RightColumn>
          <Row>
            <PictureBox>
              <img src={jason} style={{maxWidth: '60%', border: '4px solid #EB1C22'}} /><br />
              
            </PictureBox>
            <BioBox>
              <Text style={{color: '#EB1C22', fontWeight: 'bold', fontSize: '1.3em'}}>Jason Brinkley</Text><br />
              Managing Partner<br />
              <a href="mailto:jbrinkley@equiplincauctions.com" style={{color: '#EB1C22', textDecoration: 'none'}}>jbrinkley@equiplincauctions.com</a><br /><br />
              With over 30 years of experience in the auction industry, I’ve worn many hats, from operations to management, and have contributed to building, developing, and acquiring auctions throughout my career. A motto I strive to live by is, “Do the right thing when nobody’s looking,” a principle our team applies to ensure exceptional service for our customers. I’m blessed with a beautiful family, including three grown sons, one incredible grandson, and an amazing wife who is also my best friend. Our loyal dog, Ozzie, is a constant companion, joining us at the office every day and on all of our travels. Fun fact: I’ve never had a cup of coffee in my life, though I love making it for my wife each morning. 
            </BioBox>
          </Row>
          <Row>
            <PictureBox>
              <img src={jimmie} style={{maxWidth: '60%', border: '4px solid #EB1C22'}} /><br />
              
            </PictureBox>
            <BioBox>
              <Text style={{color: '#EB1C22', fontWeight: 'bold', fontSize: '1.3em'}}>Jimmie Jackson</Text><br />
              VP Sales / Partner<br />
              <a href="mailto:jjackson@equiplincauctions.com" style={{color: '#EB1C22', textDecoration: 'none'}}>jjackson@equiplincauctions.com</a><br /><br />
              As a third-generation heavy truck dealer and farm boy from Kentucky, trucks and equipment have been a part of my life from the start. Countless nights at car, farm, and estate auctions made it clear the auction business was where I belong. Since graduating from the World Wide College of Auctioneering in 1996, I’ve spent over 25 years conducting thousands of auctions and am proud to be a founding member of EquipLinc Auction Group. I’m an active member of the Used Truck Association, the National Auctioneers Association, and the Equipment Leasing & Finance Association. When I’m not on the auction block, you’ll find me deep-sea fishing, running marathons, or spending time with family and friends. The auction industry is my passion, and I’m proud to dedicate my life to it.
            </BioBox>
          </Row>
          <Row>
            <PictureBox>
              <img src={jim} style={{maxWidth: '60%', border: '4px solid #EB1C22'}} /><br />
            </PictureBox>
            <BioBox>
              <Text style={{color: '#EB1C22', fontWeight: 'bold', fontSize: '1.3em'}}>Jim Manka</Text><br />
              VP Sales / Partner<br />
              <a href="mailto:jmanka@equiplincauctions.com" style={{color: '#EB1C22', textDecoration: 'none'}}>jmanka@equiplincauctions.com</a><br /><br />
              With over 30 years of experience as a high-volume dealer specializing in buying, selling, and remarketing, I bring expertise and passion to everything I do with EquipLinc. When I’m not working, you’ll find me in my shop working on my racecar or racing my UMP Modified across the eastern U.S. I’ve been happily married to my beautiful wife, Tammie, for 10 years. I believe in always doing my best, even when no one is watching, staying positive, and approaching every situation with empathy and understanding.
            </BioBox>
          </Row>
          <Row>
            <PictureBox>
              <img src={maria} style={{maxWidth: '60%', border: '4px solid #EB1C22'}} /><br />
            </PictureBox>
            <BioBox>
              <Text style={{color: '#EB1C22', fontWeight: 'bold', fontSize: '1.3em'}}>Maria Eason</Text><br />
              Office Manager<br />
              <a href="mailto:meason@equiplincauctions.com" style={{color: '#EB1C22', textDecoration: 'none'}}>meason@equiplincauctions.com</a><br /><br />
              I’ve been in the auction industry for 4 years, focusing on invoicing and payments. My greatest joy is spending time with my six grandchildren—they dictate my hobbies! I have two grown sons, and while we live a couple of hours apart, I’m always on the road visiting them. Originally from Mexico, I was raised in Louisiana, so I speak Spanish with a hint of a country accent. Bless my heart, pero sí hablo español!
            </BioBox>
          </Row>
          <Row>
            <PictureBox>
              <img src={rebecca} style={{maxWidth: '60%', border: '4px solid #EB1C22'}} /><br />
            </PictureBox>
            <BioBox>
              <Text style={{color: '#EB1C22', fontWeight: 'bold', fontSize: '1.3em'}}>Rebecca Vessell</Text><br />
              Commercial Accounts Director<br />
              <a href="mailto:rvessell@equiplincauctions.com" style={{color: '#EB1C22', textDecoration: 'none'}}>rvessell@equiplincauctions.com</a><br /><br />
              With 31 years of experience in the auction industry, specializing in titles, transportation, and heavy trucks, I bring dedication and expertise to my work. Outside of auctions, I’m passionate about farm life, horses, and working with cows. As a proud single mom to a 28-year-old daughter and a 1-year-old granddaughter, I live by the mottos “Cowgirl Up” and “Always Pray & Never Give Up.” A simple country girl at heart, I embrace life with determination and a love for the little things.  
            </BioBox>
          </Row>
          <Row>
            <PictureBox>
              <img src={eric} style={{maxWidth: '60%', border: '4px solid #EB1C22'}} /><br />
            </PictureBox>
            <BioBox>
              <Text style={{color: '#EB1C22', fontWeight: 'bold', fontSize: '1.3em'}}>Eric Henderson</Text><br />
              National Transportation & Inspection Director<br />
              <a href="mailto:ehenderson@equiplincauctions.com" style={{color: '#EB1C22', textDecoration: 'none'}}>ehenderson@equiplincauctions.com</a><br /><br />
              With over 25 years of experience in the auction industry, I’ve held various roles, from Operations to Sales, all fueled by a passion for delivering exceptional customer service. My guiding principle comes from Philippians 4:13: "I can do all things through Christ who strengthens me." I’m a proud husband to my high school sweetheart, Nichole, and together we’ve been blessed with two amazing children: Desmond, a 22-year-old college senior, and Jasmine, a 15-year-old high school sophomore. Outside of work, I enjoy fishing, hunting, and spending quality time with my family.
            </BioBox>
          </Row>
          <Row>
            <PictureBox>
              <img src={marty} style={{maxWidth: '60%', border: '4px solid #EB1C22'}} /><br />
            </PictureBox>
            <BioBox>
              <Text style={{color: '#EB1C22', fontWeight: 'bold', fontSize: '1.3em'}}>Marty Schrednitz</Text><br />
              National Sales Director<br />
              <a href="mailto:marty@equiplincauctions.com" style={{color: '#EB1C22', textDecoration: 'none'}}>marty@equiplincauctions.com</a><br /><br />
              My auction journey began in the 1970s, when I used auctions as a dealer to manage inventory through buying and selling. I loved the energy so much that I started my own auction—just one of many stories from my exciting life. I’m passionate about travel, always seeking new experiences and sights. Family is central to my life; I have a wonderful wife, children, and grandchildren, all of whom are successful and deeply connected to me. When it comes to work, my motto is simple: “When a company tells me they want it gone, I’m the man who makes it go away!” I make things happen.  
            </BioBox>
          </Row>
          <Row>
            <PictureBox>
              <img src={tyler} style={{maxWidth: '60%', border: '4px solid #EB1C22'}} /><br />
            </PictureBox>
            <BioBox>
              <Text style={{color: '#EB1C22', fontWeight: 'bold', fontSize: '1.3em'}}>Tyler Kinard</Text><br />
              Sales Director<br />
              <a href="mailto:tyler@equiplincauctions.com" style={{color: '#EB1C22', textDecoration: 'none'}}>tyler@equiplincauctions.com</a><br /><br />
              This is my first experience in the auction industry with EquipLinc, and I’m loving the journey. I’ve been married for 12 years and have two amazing children who are passionate about travel ball. When I’m not working, you’ll find me at a softball or baseball field, traveling across the Southeast to support them in doing what they love. I believe in rising to challenges, striving for excellence, and spreading kindness and compassion to create a ripple effect of positivity for everyone.
            </BioBox>
          </Row>
          <Row>
            <PictureBox>
              <img src={brittany} style={{maxWidth: '60%', border: '4px solid #EB1C22'}} /><br />
            </PictureBox>
            <BioBox>
              <Text style={{color: '#EB1C22', fontWeight: 'bold', fontSize: '1.3em'}}>Brittany Courie</Text><br />
              Social Media & Marketing Director<br />
              <a href="mailto:brittany@equiplincauctions.com" style={{color: '#EB1C22', textDecoration: 'none'}}>brittany@equiplincauctions.com</a><br /><br />
              New to the auction industry, I bring over 10 years of experience in sales and marketing! Most of my free time is spent with my best friend—my 7-year-old son, Sage—who’s my partner for thrift shopping, exploring coffee shops, and trying new restaurants. When I get some "me" time, I love channeling my creativity into interior and web design. I’ve always worked hard and never forget where I started!
            </BioBox>
          </Row>
          <Row>
            <PictureBox>
              <img src={bre} style={{maxWidth: '60%', border: '4px solid #EB1C22'}} /><br />
            </PictureBox>
            <BioBox>
              <Text style={{color: '#EB1C22', fontWeight: 'bold', fontSize: '1.3em'}}>Bre Tucker</Text><br />
              Remarketing Director<br />
              <a href="mailto:brezhay@equiplincauctions.com" style={{color: '#EB1C22', textDecoration: 'none'}}>brezhay@equiplincauctions.com</a><br /><br />
              I’m in my second year as a Remarketing Coordinator at EquipLinc, and I absolutely love what I do! Outside of work, I enjoy traveling, shopping, and going to concerts. I’m the youngest and only daughter in my family, with two older brothers, and I credit my dad, a lifelong heavy truck driver, for inspiring my passion for the industry. A quote I hold dear is, “Every day may not be good, but there is something good in every day,” and my guiding verse is Psalm 46:5: “God is within her, she will not fail.” Fun fact: the beach is my favorite travel destination!
            </BioBox>
          </Row>
          <Row>
            <PictureBox>
              <img src={leah} style={{maxWidth: '60%', border: '4px solid #EB1C22'}} /><br />
            </PictureBox>
            <BioBox>
              <Text style={{color: '#EB1C22', fontWeight: 'bold', fontSize: '1.3em'}}>Leah Jackson</Text><br />
              Remarketing Director<br />
              <a href="mailto:ljackson@equiplincauctions.com" style={{color: '#EB1C22', textDecoration: 'none'}}>ljackson@equiplincauctions.com</a><br /><br />
              With 10 years of experience in the car auction industry, I’ve worked my way from registration clerk to Fleet Lease/Repo Assistant, gaining hands-on experience as a lane clerk for dealer and public auctions. For the past 2.5 years, I’ve been proud to be part of the EquipLinc Auction Group team. Outside of work, I cherish time with my family. Whether it’s trips to the beach, mountains, or dirt track, we love making memories together. I also enjoy crafting, creating custom T-shirts, cups, and other fun projects. Casey and I have been together for 20 years and are blessed with two wonderful kids, ages 6 and 4. I live by the motto, “Every day is a new day,” and always strive to lend a helping hand wherever I can.
            </BioBox>
          </Row>
          <Row>
            <PictureBox>
              <img src={hannah} style={{maxWidth: '60%', border: '4px solid #EB1C22'}} /><br />
            </PictureBox>
            <BioBox>
              <Text style={{color: '#EB1C22', fontWeight: 'bold', fontSize: '1.3em'}}>Hannah Jacobs</Text><br />
              Administration<br />
              <a href="mailto:hannah@equiplincauctions.com" style={{color: '#EB1C22', textDecoration: 'none'}}>hannah@equiplincauctions.com</a><br /><br />
              I’ve been with EquipLinc for a little over a year, and it’s my first experience in the auction industry. Outside of work, I love cooking, shopping, and spending time with my family and boyfriend, who I consider family too! I have two sisters, eight nieces and nephews I’m very close to, and two dogs—Rooster, a Bernedoodle, and Dunkin, a Yorkiepoo—who are like my children. My faith and family are the most important parts of my life, and I hope that shines through when people meet me
            </BioBox>
          </Row>
        </RightColumn>
      </ContentContainer>
      <CTA />
    </Container>
  )
}

export default Team;
